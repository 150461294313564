import React from 'react'
import classnames from 'classnames'

import styles from './styles.module.scss'

export interface IMobileNavContext {
  isChildOfProvider: boolean
  isOpen: boolean
  toggleMobileNav: () => void
}

const MobileNavContext = React.createContext<IMobileNavContext>({
  isChildOfProvider: false,
  isOpen: false,
  toggleMobileNav: () => undefined,
})

export const MobileNavContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const toggleMobileNav = () => setIsOpen(!isOpen)
  const backgroundClass = classnames(styles.background, {
    [styles.backgroundNavOpen]: isOpen,
  })

  React.useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  }, [isOpen])

  return (
    <MobileNavContext.Provider value={{ isChildOfProvider: true, isOpen, toggleMobileNav }}>
      <div className={backgroundClass}>{children}</div>
    </MobileNavContext.Provider>
  )
}

export default MobileNavContext
