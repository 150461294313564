import { useEffect } from 'react'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { hotjar } from 'react-hotjar'
import Script from 'next/script'

import { MobileNavContextProvider } from '../contexts/MobileNavContext'

import '../polyfills'
import '../styles/globals.scss'
import '@revelrygroup/components/dist/bundle.css'

const KalasFuneralHome: React.FC<AppProps> = ({ Component, pageProps }) => {
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_HJID && process.env.NEXT_PUBLIC_HJSV) {
      hotjar.initialize(Number(process.env.NEXT_PUBLIC_HJID), Number(process.env.NEXT_PUBLIC_HJSV))
    }
  }, [])
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
      </Head>
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}`}
        strategy="afterInteractive"
      />
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}');`,
        }}
      />
      <Script src="https://player.vimeo.com/api/player.js" strategy="afterInteractive" />

      <div id="fb-root" />

      <MobileNavContextProvider>
        <Component {...pageProps} />
      </MobileNavContextProvider>

      <div id="mobile-nav-items" />

      <div id="modal-wrapper" />
    </>
  )
}

export default KalasFuneralHome
